import React from "react"
import Layout from "../components/layout"
import Seo from "../components/global/seo"

export default function AddCompanyPage() {
  return (
    <Layout>
      <Seo
        title="Додати компанію на taho.org.ua"
        description="Додайте вашу компанію на портал для перевізників України taho.org.ua."
      />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 py-12 sm:py-24">
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h1 className="text-2xl font-medium leading-6 text-gray-900 mb-6">
                  Додати компанію в каталог
                </h1>
                <p className="text-gray-600 mb-4">
                  Якщо ви надаєте послуги для перевізників, можете додати вашу
                  компанію до каталогу taho.org.ua.
                </p>
                <p className="text-gray-600 mb-4">
                  Надішліть заповнену форму і після перевірки інформації
                  компанія з'явиться в переліку компаній.
                </p>
                <p className="text-gray-600 mb-4">
                  Вкажіть, будь ласка, дійсний контакт особи, відповідальної за
                  інформацію про компанію.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form
                data-netlify="true"
                netlify-honeypot="bot-field"
                name="addCompanyForm"
                form-name="addCompanyForm"
                method="POST"
                action="/thankyou/"
                id="addCompanyForm"
              >
                <input
                  className="hidden"
                  type="hidden"
                  name="form-name"
                  value="addCompanyForm"
                />
                <input className="hidden" type="text" name="bot-field" />
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6 grid grid-cols-1 gap-4">
                    <label>
                      <span className="text-gray-700 required-field">
                        Повна юридична назва Компанії
                      </span>
                      <input
                        name="CompanyName"
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700 required-field">
                        Телефон компанії
                      </span>
                      <input
                        name="CompanyPhone"
                        type="tel"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700">Електронна пошта</span>
                      <input
                        name="CompanyEmail"
                        type="email"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700">Контактна особа</span>
                      <input
                        name="CompanyContactName"
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700 required-field">
                        Адреса компанії
                      </span>
                      <input
                        name="CompanyAddress"
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700 required-field">
                        Перелік послуг
                      </span>
                      <textarea
                        name="CompanyServices"
                        rows={3}
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700 required-field">
                        Графік роботи
                      </span>
                      <textarea
                        name="CompanyWorkingHours"
                        rows={2}
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <label>
                      <span className="text-gray-700">
                        Посилання на ваш сайт або бізнес-сторінку
                      </span>
                      <input
                        name="CompanyUrls"
                        type="url"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </label>
                    <details>
                      <summary className="text-gray-700">
                        додаткова інформація
                      </summary>
                      <textarea
                        name="CompanyAdditionalInfo"
                        rows={2}
                        type="text"
                        className="w-full mt-1 block rounded-md border-gray-300 shadow-sm focus:border-yellow-300 focus:ring focus:ring-yellow-200 focus:ring-opacity-50"
                      />
                    </details>
                    <div>
                      <label className="inline-flex items-center">
                        <input
                          name="isAgreeToRules"
                          type="checkbox"
                          className="rounded
                          border-gray-300
                          text-yellow-600
                          shadow-sm
                          focus:border-yellow-300
                          focus:ring
                          focus:ring-offset-0
                          focus:ring-yellow-200
                          focus:ring-opacity-50"
                        />
                        <span className="ml-2 required-field">
                          Згода з{" "}
                          <a
                            href="/rules/"
                            target="_blank"
                            className="text-yellow-700 underline hover:text-yellow-900"
                          >
                            правилами
                          </a>
                        </span>
                      </label>
                    </div>
                  </div>

                  <div className="px-4 py-3 bg-gray-50 sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    >
                      Відправити
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
